var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"visits-table",attrs:{"disabled":!_vm.isOnline}},[_c('el-table',{staticClass:"not-for-print",attrs:{"data":_vm.visits,"row-class-name":_vm.generateRowClasses}},[_c('el-table-column',{attrs:{"label":"Visit","prop":"visitId","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"visit-info",class:{ 'disabled': !row.instanceID && _vm.isCTMSActive },on:{"click":function($event){return _vm.$emit('row-click', row)}}},[_c('StatusIcon',{attrs:{"status":row.status}}),_c('span',[_vm._v(_vm._s(row.name))])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Date","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getVisitDate(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Site","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getSiteName(row))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"48"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.hasContextOptions(row))?_c('ContextMenu',[(_vm.displayDidNotCompleteOption(row))?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.$emit('skip-visit', row)}}},[_vm._v(" Did Not Complete ")]):_vm._e()],1):_vm._e()]}}])})],1),_vm._l((_vm.totalPrintedPages),function(page){return _c('div',{key:page,staticClass:"print-only print-page",class:{ 'paged': page > 1 }},[_c('table',{staticClass:"printer-table"},[_c('caption',{staticClass:"visually-hidden"},[_vm._v(" A printer-friendly visit schedule (page "+_vm._s(page)+") ")]),_vm._m(0,true),_c('tbody',_vm._l((_vm.visits.slice(_vm.visitsPerPage * (page - 1), _vm.visitsPerPage * page)),function(visit){return _c('tr',{key:visit.id},[_c('td',{staticClass:"visit-info"},[_c('StatusIcon',{attrs:{"status":visit.status}}),_c('span',[_vm._v(_vm._s(visit.name))])],1),_c('td',[_vm._v(" "+_vm._s(_vm.getVisitDate(visit))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getSiteName(visit))+" ")])])}),0)]),(page < _vm.totalPrintedPages)?[_c('span',{staticClass:"page-number"},[_vm._v(" "+_vm._s(page)+" of "+_vm._s(_vm.totalPrintedPages)+" ")])]:[_c('table',{staticClass:"printer-table key-table"},[_c('caption',{staticClass:"visually-hidden"},[_vm._v(" A key to understand the icons used within the visit schedule ")]),_vm._m(1,true),_c('tbody',[_c('tr',[_c('td',[_c('StatusIcon',{attrs:{"status":"complete"}}),_c('span',[_vm._v("Completed")])],1)]),_c('tr',[_c('td',[_c('StatusIcon',{attrs:{"status":"not_completing"}}),_c('span',[_vm._v("Did Not Complete")])],1)]),_c('tr',[_c('td',[_c('StatusIcon',{attrs:{"status":"in_progress"}}),_c('span',[_vm._v("In Progress")])],1)]),_c('tr',[_c('td',[_c('StatusIcon',{attrs:{"status":"not_started"}}),_c('span',[_vm._v("Upcoming")])],1)])])]),_c('span',{staticClass:"page-number"},[_vm._v(" "+_vm._s(page)+" of "+_vm._s(_vm.totalPrintedPages)+" ")])]],2)})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Visit ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Date ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Site ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Visit Key ")])])])}]

export { render, staticRenderFns }