<script>
/**
 * A component to display information about a Visit Window in a box display.
 * Used to display the active visit window.
 */
import VisitsInWindow from '@/components/VisitsInWindow/VisitsInWindow'
import detectModule from '@/mixins/detectModule'
import { getVisitWindowLabel, isVisitWindowInProgress } from '@/utils/visit'
import { mapState } from 'vuex'
import participant from '../../mixins/queries/participant'

export default {
  components: {
    VisitsInWindow
  },
  mixins: [detectModule, participant],
  props: {
    visitWindow: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['isOnline']),

    visitWindowLabel() {
      return getVisitWindowLabel(this.visitWindow, this.participant.protocols)
    },

    isInProgress() {
      return isVisitWindowInProgress(this.visitWindow)
    }
  },
  methods: {
    // TODO: Open a window instead of a visit. To be done after visit window SoA is completed.
    openVisitWindow() {
      if (this.visitWindow) {
        this.$emit('box-click', this.visitWindow)
      }
    }
  }
}
</script>

<template>
  <div class="visit-window-box">
    <div class="info">
      <div class="title">
        <span class="title-label">
          {{ visitWindow.inProgress || (new Date() >= Date.parse(visitWindow.startDate)) ? 'Current' : 'Next' }}
          Visit Window
        </span>
        <h5>
          {{ visitWindowLabel }}
        </h5>
      </div>
      <VisitsInWindow :visits="visitWindow.visits" />
    </div>
    <div class="actions">
      <el-button
        v-if="!(isCTMSActive && !isInProgress)"
        type="primary"
        :disabled="!isOnline"
        @click="openVisitWindow"
      >
        View Assessments
      </el-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .visit-window-box {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid $axon;
    background-color: $white-matter;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }

    .title {
      display: grid;
      grid-template: auto auto / 1fr;

      .title-label {
        color: $hillcock;
        @include text-style('paragraph', 'small', 'bold');
        text-transform: uppercase;
      }

      h5 {
        margin: 0;
        margin-bottom: .25rem;
        @include text-style('title', 'large', 'bold');
      }
    }

    .actions {
      margin-left: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .window-visits .visit-name {
      min-width: 17.5rem;
    }

    @media print {
      padding: 0;
      border: none;

      .title {
        margin-bottom: .75rem;

        .title-label {
          margin-bottom: 1rem;
          color: $black;
          @include text-style('title', 'medium', 'medium');
          text-transform: none;
        }

        h5 {
          @include text-style('interface', 'medium', 'medium');
        }
      }

      button {
        display: none;
      }
    }
  }
</style>
