<script>
/* eslint-disable */
import VisitsInWindow from '@/components/VisitsInWindow/VisitsInWindow'
import BfAlert from '@/components/BfAlert/BfAlert'
import Pagination from '@/components/Pagination/Pagination'
import { getVisitWindowLabel } from '@/utils/visit'
import EmptyState from "@/components/EmptyState/EmptyState";
import visitInstanceStates from '@/mixins/visitInstanceStates'
import { propOr } from 'ramda'
import { VisitStatus } from '@/utils/constants'
import StatusPill from '@/components/StatusPill/StatusPill'
import participant from '@/mixins/queries/participant'

export default {
  components: {
    EmptyState,
    VisitsInWindow,
    BfAlert,
    Pagination,
    StatusPill
  },
  mixins: [
    participant
  ],
  props: {
    title: {
      type: String,
      default: ''
    },
    visitWindows: {
      type: Array,
      required: true
    },
    canNavigate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      openVisitWindows: [],
      pagination: {
        page: 1,
        perPage: 5
      }
    }
  },
  computed: {
    /*
     * Determine the visit windows to display based on pagination values
     */
    visitWindowsToDisplay() {
      // pagination calculation
      const begin = this.pagination.perPage * (this.pagination.page - 1)
      const end = this.pagination.perPage * this.pagination.page
      return this.visitWindows.slice(begin, end)
    }
  },
  methods: {
    getWindowLabel(visitWindow) {
      return getVisitWindowLabel(visitWindow, this.participant.protocols)
    },

    /*
     * When a page is changed, update our query
     */
    handlePageChange(newPage) {
      this.pagination.page = newPage
    },

    /*
     * When the perPage value is modified, update our query
     */
    handlePerPageChange(newPerPage) {
      this.pagination.perPage = newPerPage
      this.handlePageChange(1) // reset to page 1
    },

    navigateToVisitWindow(event, visitWindow) {
      event.stopPropagation()
      // TODO: pass entire window instead of just one visit
      this.$emit('open', visitWindow)
    },

    getReopenedVisit(visitWindow) {
      return visitWindow.visits
        .flatMap(visit => propOr([], 'visitInstance', visit))
        .find(visit => visit.status === VisitStatus.REOPENED)
    }
  }
}
</script>

<template>
  <div class="visit-window-accordion">
    <h3 v-if="title">
      {{ title }}
    </h3>
        <el-collapse v-model="openVisitWindows">
          <el-collapse-item
            v-for="(visitWindow, index) in visitWindowsToDisplay"
            :key="index"
            class="visit-window"
            :title="getWindowLabel(visitWindow)"
            :name="getWindowLabel(visitWindow)"
          >
            <template
              v-if="canNavigate"
              slot="title"
            >
              <div class="visit-window-label">
                <div>{{ getWindowLabel(visitWindow) }}</div>
                <StatusPill
                  v-if="getReopenedVisit(visitWindow)"
                  status="reopened"
                  text="Reopened Visit"
                />
              </div>
              <el-button
                type="ghost"
                class="visit-window-link"
                @click="e => navigateToVisitWindow(e, visitWindow)"
              >
                View Assessments
              </el-button>
            </template>
            <BfAlert
              v-if="index === 0 && !canNavigate"
              icon="warning"
            >
              Available once all assessments within the current visit window are complete
            </BfAlert>
            <VisitsInWindow :visits="visitWindow.visits" />
          </el-collapse-item>
        </el-collapse>
        <Pagination
          v-if="visitWindows.length > 5"
          :total="visitWindows.length"
          :page="pagination.page"
          :per-page="pagination.perPage"
          :count-options="[5,10,25,50]"
          @pagechanged="handlePageChange"
          @perpage-changed="handlePerPageChange"
        />
    <EmptyState
        v-if="!visitWindows.length"
        title="A full visit schedule will be generated once this participant has been screened and enrolled"
        image="window"
        :image-above-text="true"
        :display-in-table="true"
    ></EmptyState>
  </div>
</template>

<style lang="scss">
.visit-window-accordion {
  margin: 2.5rem 0;

  h3 {
    margin: 0;
    margin-bottom: 1rem;
    @include text-style('title', 'medium', 'medium');
  }

  .el-collapse {
    border: 1px solid $axon;
    background: $white-matter;
  }

  .el-collapse-item {
    &__header {
      justify-content: space-between;
      position: relative;
      min-height: 3.75rem;
      padding: 1.25rem;
      padding-left: 3rem;
      background: transparent;
      color: $black;
      @include text-style('interface', 'medium', 'medium');
      transition: all .3s;

      &:hover,
      &.focusing:focus:not(:hover) {
        color: $gaba;
      }

      i {
        position: absolute;
        left: 1rem;
        font-size: 1.25rem;
        transform: rotate(90deg);

        &.is-active {
          transform: rotate(-90deg);
        }

        @media screen and  (max-width: $breakpoint-mobile) {
          left: .5rem;
        }
      }
    }

    &__content {
      padding: 0 2.5rem 1.5rem 1.5rem;
    }

    .bf-alert {
      padding: .5rem .75rem;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .window-visits {
    padding-left: 1.5rem;
  }

  .pagination {
    margin-top: 1rem;
    @include text-style('interface', 'small', 'medium');
  }

  .visit-window-label {
    display: flex;
    align-items: center;

    div:first-child {
      margin-right: 16px;
    }
  }

  @media print {
    margin-bottom: 2.5rem;

    .el-collapse {
      border: none;
    }

    .el-collapse-item {
      margin-bottom: 1.5rem;

      &__header {
        height: auto;
        min-height: auto;
        padding: 0;
        border: none;
        margin-bottom: .75rem;

        i {
          display: none;
        }
      }

      &__wrap {
        display: block !important;
        border: none;
      }

      &__content {
        padding: 0;
      }

      .bf-alert {
        display: none;
      }
    }

    .window-visits {
      padding-left: 0;
    }

    button {
      display: none;
    }

    .pagination {
      display: none;
    }
  }
}
</style>
