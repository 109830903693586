<script>
import VisitSchedule from '@/views/shared/VisitSchedule'
import VisitScheduleOld from '@/views/shared/VisitScheduleOld'
import { Features } from '@/utils/constants'

export default {
  components: {
    VisitSchedule,
    VisitScheduleOld
  },
  computed: {
    isVisitWindowsSupported() {
      return this.$store.getters.supportedFeatures.includes(Features.VISIT_WINDOWS)
    }
  }
}
</script>
<template>
  <div>
    <VisitSchedule v-if="isVisitWindowsSupported" />
    <VisitScheduleOld v-else />
  </div>
</template>
