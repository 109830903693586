<template>
  <div
    class="visits-table"
    :disabled="!isOnline"
  >
    <el-table
      :data="visits"
      :row-class-name="generateRowClasses"
      class="not-for-print"
    >
      <el-table-column
        label="Visit"
        prop="visitId"
        sortable
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <a
            class="visit-info"
            :class="{ 'disabled': !row.instanceID && isCTMSActive }"
            @click="$emit('row-click', row)"
          >
            <StatusIcon :status="row.status" />
            <span>{{ row.name }}</span>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        label="Date"
        width="250"
      >
        <template v-slot="{ row }">
          {{ getVisitDate(row) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Site"
        width="250"
      >
        <template v-slot="{ row }">
          {{ getSiteName(row) }}
        </template>
      </el-table-column>
      <el-table-column width="48">
        <template v-slot="{ row }">
          <ContextMenu v-if="hasContextOptions(row)">
            <el-dropdown-item
              v-if="displayDidNotCompleteOption(row)"
              @click.native="$emit('skip-visit', row)"
            >
              Did Not Complete
            </el-dropdown-item>
          </ContextMenu>
        </template>
      </el-table-column>
    </el-table>

    <!-- printer-friendly version: -->
    <div
      v-for="page in totalPrintedPages"
      :key="page"
      class="print-only print-page"
      :class="{ 'paged': page > 1 }"
    >
      <table class="printer-table">
        <caption class="visually-hidden">
          A printer-friendly visit schedule (page {{ page }})
        </caption>
        <thead>
          <tr>
            <th scope="col">
              Visit
            </th>
            <th scope="col">
              Date
            </th>
            <th scope="col">
              Site
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="visit in visits.slice(visitsPerPage * (page - 1), visitsPerPage * page)"
            :key="visit.id"
          >
            <td class="visit-info">
              <StatusIcon :status="visit.status" />
              <span>{{ visit.name }}</span>
            </td>
            <td>
              {{ getVisitDate(visit) }}
            </td>
            <td>
              {{ getSiteName(visit) }}
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="page < totalPrintedPages">
        <span class="page-number">
          {{ page }} of {{ totalPrintedPages }}
        </span>
      </template>
      <template v-else>
        <table class="printer-table key-table">
          <caption class="visually-hidden">
            A key to understand the icons used within the visit schedule
          </caption>
          <thead>
            <tr>
              <th scope="col">
                Visit Key
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StatusIcon status="complete" />
                <span>Completed</span>
              </td>
            </tr>
            <tr>
              <td>
                <StatusIcon status="not_completing" />
                <span>Did Not Complete</span>
              </td>
            </tr>
            <tr>
              <td>
                <StatusIcon status="in_progress" />
                <span>In Progress</span>
              </td>
            </tr>
            <tr>
              <td>
                <StatusIcon status="not_started" />
                <span>Upcoming</span>
              </td>
            </tr>
          </tbody>
        </table>
        <span class="page-number">
          {{ page }} of {{ totalPrintedPages }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import Caret from '@/components/Caret/Caret'
import StatusIcon from '@/components/StatusIcon/StatusIcon'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import detectModule from '@/mixins/detectModule'
import { getVisitInstanceDisplayDate } from '@/utils/visit'
import { datePlusDays, displayAbbreviatedDate } from '@/utils/date'
import { VisitStatus, FormStatus } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { mapState } from 'vuex'

export default {
  components: {
    Caret,
    StatusIcon,
    ContextMenu
  },
  mixins: [ detectModule ],
  props: {
    visits: {
      type: Array,
      required: true
    },
    scheduleBaseDate: {
      type: String,
      default: ''
    },
    activeVisit: {
      type: Object,
      default: () => {}
    },
    sites: {
      type: Array,
      default: () => []
    }
  },
  apollo: {
    activeVisitData() {
      return {
        query: GET_VISIT_QUERY,
        variables() {
          return { visitInstanceId: this.activeVisit.instanceID }
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'VisitBox.vue get visit query')
        },
        skip() {
          return !this.activeVisit.instanceID
        }
      }
    }
  },
  data() {
    return {
      activeVisitData: {
        formInstances: []
      },
      visitsPerPage: 10 // number of visits to display in the printed table, per page
    }
  },
  computed: {
    ...mapState(['isOnline']),

    totalPrintedPages() {
      return Math.ceil(this.visits.length / this.visitsPerPage)
    },

    /**
     * Determine if any data has been added to the active visit.
     * @returns {boolean}
     */
    hasActiveVisitData() {
      return this.activeVisitData.formInstances.some(formInstance => formInstance.status !== FormStatus.NOT_STARTED)
    }
  },
  methods: {
    generateRowClasses({ row }) {
      const rowClass = row.status || 'ready'
      return this.isActiveVisit(row.id) ? rowClass.concat(' next') : rowClass
    },

    getVisitDate(visit) {
      const visitDisplayDate = getVisitInstanceDisplayDate(visit)
      if (visitDisplayDate) {
        return displayAbbreviatedDate(visitDisplayDate)
      } else if (visit.status === VisitStatus.NOT_COMPLETING) {
        return '—'
      }

      // get the visit window for future visits
      return this.estimateVisitWindow(visit)
    },

    getSiteName(visit) {
      if (visit.siteId && this.sites.length) {
        return this.sites.find(site => site.id === visit.siteId).name
      }

      return '—'
    },

    /*
     * The window will be based off the calculation date.
     * Use the calculation date to determine the estimated visit date.
     */
    estimateVisitWindow(visit) {
      if (this.scheduleBaseDate) {
        // find the estimated date
        const estimatedVisitDate = datePlusDays(this.scheduleBaseDate, visit.scheduledDay)
        // subtract the windowDelta to get the beginning of the visit window
        const windowStart = datePlusDays(estimatedVisitDate, -visit.windowDelta)
        // add the windowDelta to get the end of the visit window
        const windowEnd = datePlusDays(estimatedVisitDate, visit.windowDelta)
        return `${displayAbbreviatedDate(windowStart)} - ${displayAbbreviatedDate(windowEnd)}`
      }
      return '—'
    },

    isActiveVisit(visitId) {
      return this.activeVisit && this.activeVisit.id === visitId
    },

    /**
     * Check if the visit is skippable.
     * If a visit has been started but no data is associated with it, it can be marked as "Did Not Complete".
     * @returns {boolean}
     */
    displayDidNotCompleteOption(visit) {
      if (!this.isEDCActive) { return false }
      return visit.isSkippable && this.isActiveVisit(visit.id) && !this.hasActiveVisitData
    },

    hasContextOptions(visit) {
      return this.displayDidNotCompleteOption(visit)
    }
  }
}
</script>

<style lang="scss">
.visits-table {
  border: 1px solid $axon;
  border-radius: 2px;

  a {
    text-decoration: none;
  }

  .visit-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $dopastat;

    svg {
      width: 15px;
      height: 15px;
      min-height: 15px;
      min-width: 15px;
      margin-right: .625rem;
    }

    &.disabled {
      color: black;
      pointer-events: none;

      svg {
        fill: black;
      }
    }
  }

  .next {
    svg {
      fill: $dopastat;
    }
  }

  .ready:not(.next) a,
  .not_completing a {
    color: $black;
    pointer-events: none;
  }

  .print-page {
    position: relative;
    min-height: 700px;
    min-height: 670px;
    padding-bottom: 2.5rem;
    page-break-after: always;

    &.paged {
      padding-top: 2rem;
      min-height: 900px;
      min-height: 850px;
    }
  }

  .printer-table {
    width: 100%;
    border-collapse: collapse; // needed to add borders to <tr>s

    caption {
      display: none;
    }

    tbody tr {
      border-top: 1px solid $axon;
      page-break-inside: avoid;
    }

    th, td {
      padding: 1rem 0;
      page-break-inside: avoid;

      // set visit column width
      &:nth-of-type(2) {
        width: 200px;
      }
    }

    th {
      padding: 1rem;
      text-align: left;
      vertical-align: bottom;
      @include text-style('interface', 'small', 'bold');
    }

    td {
      vertical-align: top;
      color: $black;
      @include text-style('interface', 'small', 'regular')
    }

    svg {
      fill: $black !important;
    }
  }

  .page-number {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    color: $hillcock;
    @include text-style('interface', 'medium', 'regular');
  }

  .key-table {
    max-width: 10rem;

    th {
      padding-top: 2rem;
    }

    tbody tr {
      border: none;

      &:first-of-type {
        border-top: 1px solid $axon;

        td {
          padding-top: 1rem;
        }
      }
    }

    td {
      padding: .5rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin-right: .625rem;
      }
    }
  }
}
</style>
