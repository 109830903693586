import GET_PARTICIPANT_SITE_TRANSFERS from '@/graphql/participants/GetParticipantSiteTransfersQuery.graphql'
import currentUser from '@/mixins/queries/currentUser'
import participantVisits from '@/mixins/queries/participantVisits'
import { getInProgressVisits } from '@/utils/visit'
import { CTMSPermission } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { userHasStudyPermission } from '@/utils/user'

export default {
  mixins: [currentUser, participantVisits],
  apollo: {
    participantSiteTransfers() {
      return {
        query: GET_PARTICIPANT_SITE_TRANSFERS,
        variables: {
          participantId: this.$route.params.participantId,
          studyId: this.$route.params.studyId
        },
        update: data => data.getParticipantSiteTransfers,
        error (error) {
          logError(error, 'ParticipantDetail.vue site transfers query')
        },
        skip() {
          // only run this query on the CTMS
          return !this.isCTMSActive || !this.canViewSiteTransfers
        }
      }
    }
  },
  data() {
    return {
      participantSiteTransfers: [],
      displaySiteTransferModal: false
    }
  },
  computed: {
    /**
     * Check a user's permissions to determine if they can see the option to initiate site transfers.
     * @returns {boolean}
     */
    canManageSiteTransfers() {
      return this.currentUser && userHasStudyPermission(
        this.currentUser,
        this.$route.params.studyId,
        CTMSPermission.UPDATE_PARTICIPANT_SITE)
    },

    /**
     * Get all in-progress visits. This conditional is used to determine if a participant can trasfer sites.
     * A user may not transfer a participant that has a visit in-progress.
     * @returns {boolean}
     */
    hasInProgressVisit() {
      return this.participantVisits && getInProgressVisits(this.participantVisits).length > 0
    },

    /**
     * Return the list of siteTransfers, with the fromSite's name included.
     * Note: The siteTransfers array is only populated in the CTMS module.
     *
     * @return {array} the array of siteTransfers
     */
    siteTransfers() {
      return this.participantSiteTransfers.map(
        (siteTransfer, index) => ({
          ...siteTransfer,
          fromSiteName: this.getSiteName(siteTransfer.fromSiteId),
          destinationSite: this.calculateDestinationSite(index)
        }))
    }
  },
  methods: {
    /**
     * Calculate the destination site by looking up the following transfer's fromSite.
     * Returns the current site if no next transfer exists in the siteTransfers array.
     *
     * @param {string} index - The transfer object's index in the siteTransfers array.
     * @returns {string} a site name.
     */
    calculateDestinationSite(index) {
      return this.participantSiteTransfers[index - 1]
        ? this.getSiteName(this.participantSiteTransfers[index - 1].fromSiteId)
        : this.getSiteName(this.participant.siteId)
    },

    /**
     * If there are no in-progress visits, open the modal on click.
     */
    openSiteTransferModal() {
      if (!this.hasInProgressVisit) {
        this.displaySiteTransferModal = true
      }
    }
  }
}
