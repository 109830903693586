<template>
  <div class="visit-schedule">
    <ParticipantHeader
      :participant="participant"
      :loading="$apollo.queries.participant.loading"
      :is-e-d-c-active="isEDCActive"
    >
      <template v-if="isCTMSActive">
        <dl class="participant-details">
          <div class="participant-details__field">
            <dt>Status</dt>
            <dd>{{ participantStatus | capitalize }}</dd>
          </div>
          <div class="participant-details__field">
            <dt>Cohort</dt>
            <dd>{{ getCohortName(participant.cohortId) }}</dd>
          </div>
          <div
            v-if="participantSubgroups"
            class="participant-details__field"
          >
            <dt>Subgroups</dt>
            <dd>{{ participantSubgroups }}</dd>
          </div>
          <div class="participant-details__field">
            <dt>Site</dt>
            <dd>
              {{ getSiteName(participant.siteId) }}
              <Tooltip
                v-if="canManageSiteTransfers"
                class="participant-details__manage-site"
                :condition="hasInProgressVisit"
                content="Cannot change site with in-progress visit"
                placement="right"
              >
                <a @click="openSiteTransferModal">
                  Manage Site
                </a>
              </Tooltip>
            </dd>
          </div>
        </dl>
      </template>
    </ParticipantHeader>

    <!-- Print-only header -->
    <div class="print-only print-header">
      <p class="print-header__title">
        Visit Schedule
      </p>
      <p class="print-header__info">
        {{ `Participant ID: ${participant.customerDefinedId} • ${getSiteName(participant.siteId)}` }}
      </p>
      <p class="print-header__disclaimer">
        Please Note: redact the Participant ID before sharing schedule with participant
      </p>
    </div>
    <BfAlert
      v-if="getReopenedVisit && isQueryResolutionSupported"
      type="info-pink"
      icon="unlocked"
      class="visit-schedule-reopened-alert"
    >
      There is a reopened visit. You can make changes or mark this visit as complete.
      <a @click="openVisitWindow(getReopenedVisit)">View Visit</a>
    </BfAlert>
    <VisitWindowBox
      v-if="activeVisitWindow && !isParticipantExcludedOrCompleted"
      v-loading="$apollo.queries.visitWindows.loading"
      :visit-window="activeVisitWindow"
      @box-click="openVisitWindow"
    />

    <VisitWindowAccordion
      v-if="!isParticipantExcludedOrCompleted"
      v-loading="$apollo.queries.visitWindows.loading"
      title="Upcoming Visit Windows"
      :visit-windows="!getScheduleBaseDate ? [] : upcomingVisitWindows"
      :can-navigate="false"
    />

    <VisitWindowAccordion
      v-if="completedVisitWindows.length"
      title="Completed Visit Windows"
      :visit-windows="completedVisitWindows"
      @open="openVisitWindow"
    />

    <SiteTransferLog
      v-if="siteTransfers.length"
      :site-transfers="siteTransfers"
    />

    <!-- TODO: Move modals to single VisitWindow / SoA view -->
    <StartVisitModal
      :visit="visitToStart"
      :display-modal="displayStartVisitModal"
      route-to-visit
      @close="displayStartVisitModal = false"
    />

    <SkipVisitModal
      v-if="displaySkipVisitModal"
      :visit="visitToSkip"
      :visit-instance-id="visitToSkip.instanceID ? visitToSkip.instanceID : null"
      @close="displaySkipVisitModal = false"
    />

    <SiteTransferModal
      v-if="displaySiteTransferModal"
      :participant="participant"
      :sites="sites"
      @close="displaySiteTransferModal = false"
    />
  </div>
</template>

<script>
import ParticipantHeader from '@/components/PageHeader/ParticipantHeader'
import Tooltip from '@/components/Tooltip/Tooltip'
import VisitWindowBox from '@/components/VisitWindowBox/VisitWindowBox'
import VisitWindowAccordion from '@/components/VisitWindowAccordion/VisitWindowAccordion'
import SiteTransferLog from '@/components/SiteTransferLog/SiteTransferLog'
import SiteTransferModal from '@/components/SiteTransferModal/SiteTransferModal'
import StartVisitModal from '@/components/StartVisitModal/StartVisitModal'
import SkipVisitModal from '@/components/SkipVisitModal/SkipVisitModal'
import detectModule from '@/mixins/detectModule'
import participant from '@/mixins/queries/participant'
import sites from '@/mixins/queries/sites'
import cohorts from '@/mixins/queries/cohorts'
import siteTransfers from '@/mixins/queries/siteTransfers'
import participantVisits from '@/mixins/queries/participantVisits'
import visitWindows from '@/mixins/queries/visitWindows'
import BfAlert from '@/components/BfAlert/BfAlert'
import { mapState } from 'vuex'
import { Features, ParticipantProtocolStatus } from '@/utils/constants'
import { getReopenedVisitWindow } from '@/utils/visit'
import { windows } from '@/utils/stubs/upcoming-visit-windows'
import { isParticipantExcluded, getParticipantStatus } from '@/ppmi/participants'

export default {
  components: {
    ParticipantHeader,
    Tooltip,
    VisitWindowBox,
    VisitWindowAccordion,
    SiteTransferLog,
    SiteTransferModal,
    StartVisitModal,
    SkipVisitModal,
    BfAlert
  },
  mixins: [
    detectModule,
    participant,
    sites,
    cohorts,
    siteTransfers,
    participantVisits,
    visitWindows
  ],
  data() {
    /**
     * TODO: Rework when new SoA is completed.
     */
    return {
      displayStartVisitModal: false,
      displaySkipVisitModal: false,
      visitToStart: {}, // this will be the visit passed to NewVisitModal
      visitToSkip: {},
      reopenedVisit: {},
      windows
    }
  },
  computed: {
    ...mapState(['isOnline']),
    isQueryResolutionSupported() {
      return this.$store.getters.supportedFeatures.includes(Features.QUERY_RESOLUTION)
    },
    getReopenedVisit() {
      return getReopenedVisitWindow(this.visitWindows)
    },
    getScheduleBaseDate() {
      return this.participant?.protocols && this.participant.protocols[0].scheduleBaseDate
    },
    isParticipantExcludedOrCompleted() {
      return isParticipantExcluded(this.participant) ||
        getParticipantStatus(this.participant) === ParticipantProtocolStatus.COMPLETE
    }
  },
  methods: {
    /**
     * Moving open visit functionality here until the new SoA is implemented.
     * TODO: Remove and open visit window instead.
     */
    openVisitWindow(visitWindow) {
      this.$router.push({
        name: 'scheduleOfActivities',
        params: {
          participantId: this.$route.params.participantId,
          visitWindowId: visitWindow.visitWindowId,
          studyId: this.$route.params.studyId
        }
      })
      // this.startOrResumeVisit(formatVisit(visit))
    },

    skipVisit(visit) {
      this.visitToSkip = visit
      this.displaySkipVisitModal = true
    },

    startOrResumeVisit(visit) {
      if (!this.isOnline) { return } // cancel action if offline
      if (visit.instanceID) {
        // load the visitInstance if visit is in_progress or complete
        this.resumeVisit(visit)
      } else if (this.isEDCActive) {
        // display the Start Visit Modal
        this.visitToStart = visit
        this.displayStartVisitModal = true
      }
    },

    resumeVisit(visit) {
      this.$router.push({
        name: !this.isEDCActive ? 'visitDetail' : 'visitManagement',
        params: {
          ...this.$route.params,
          visitInstanceId: visit.instanceID
        }
      })
    }
  }

}
</script>

<style lang="scss">
.visit-schedule-reopened-alert {
  margin-bottom: 1rem;
}

.print-header {
  margin-bottom: 2.5rem;

  > * {
    margin: 0;
  }

  &__title {
    margin-bottom: .75rem;
    @include text-style('title', 'large', 'bold');
  }

  &__info {
    margin-bottom: 1rem;
    @include text-style('interface', 'medium', 'regular');
  }

  &__disclaimer {
    @include text-style('interface', 'small', 'regular');
    font-style: italic;
  }
}

@media print {
  .visit-schedule {
    padding: 2.5rem;
  }

  .alert-reopened {
    margin-bottom: 1rem;
  }

  .header.participant-header {
    display: none;
  }
}
</style>
