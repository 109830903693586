<template>
  <section class="site-transfer-log">
    <h3 class="site-transfer-log__heading">
      Site Transfer History
    </h3>
    <el-table :data="siteTransfers">
      <el-table-column
        label="Originating Site"
        prop="fromSiteName"
        min-width="235"
      />
      <el-table-column
        label="Destination Site"
        prop="destinationSite"
        min-width="235"
      />
      <el-table-column
        label="Initiated By"
        min-width="185"
      >
        <template v-slot="{ row }">
          {{ row.transferredBy.firstName }} {{ row.transferredBy.lastName }}
        </template>
      </el-table-column>
      <el-table-column
        label="Date"
        prop="transferredAt"
        min-width="150"
      >
        <template v-slot="{ row }">
          {{ abbreviatedDate(row.transferredAt) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Reason"
        prop="reason"
        min-width="300"
      />
    </el-table>
  </section>
</template>

<script>
import { displayAbbreviatedDate } from '@/utils/date'

export default {
  props: {
    siteTransfers: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Wrapper function to use displayAbbreviatedDate in template.
     *
     * @param {string} dateString - The date returned by the API
     * @returns {string} a user-friendly date string.
     */
    abbreviatedDate(dateString) {
      return displayAbbreviatedDate(dateString)
    }
  }
}
</script>

<style lang="scss">
  .site-transfer-log {
    padding: 2rem 0;

    &__heading {
      margin: .5rem 0 1rem;
      @include text-style('title', 'medium', 'medium');
    }
  }
</style>
