export const windows = [
  // {
  //   'startDate': '2022-11-14',
  //   'endDate': '2023-02-12',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'c04398e8-0e11-4deb-a7e0-cf1fc804043f',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 05 (Month 18)',
  //         'code': 'V05',
  //         'position': 4,
  //         'scheduledDay': 540,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // }
  // {
  //   'startDate': '2023-05-13',
  //   'endDate': '2023-08-11',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'ed5b5e03-3fdb-40ce-bafc-20ce7b5416a0',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 06 (Month 24)',
  //         'code': 'V06',
  //         'position': 5,
  //         'scheduledDay': 720,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2023-11-09',
  //   'endDate': '2024-02-07',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'd07767a0-590c-46dd-b3a0-15acc52414d2',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 06 (Month 30)',
  //         'code': 'R06',
  //         'position': 6,
  //         'scheduledDay': 900,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2024-05-07',
  //   'endDate': '2024-08-05',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'd9249e39-7d36-4259-a81d-ab898da39499',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 08 (Month 36)',
  //         'code': 'V08',
  //         'position': 7,
  //         'scheduledDay': 1080,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2024-11-03',
  //   'endDate': '2025-02-01',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'a232fac5-2ede-4416-87ff-2b22a8e7d036',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 08 (Month 42)',
  //         'code': 'R08',
  //         'position': 8,
  //         'scheduledDay': 1260,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2025-05-02',
  //   'endDate': '2025-07-31',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'e52fdfca-bcdc-4908-aea7-9e7f7e91e117',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 10 (Month 48)',
  //         'code': 'V10',
  //         'position': 9,
  //         'scheduledDay': 1440,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2025-10-29',
  //   'endDate': '2026-01-27',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'c3a70755-c84d-4bc0-a0a1-a5bffaaeaa23',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 10 (Month 54)',
  //         'code': 'R10',
  //         'position': 10,
  //         'scheduledDay': 1620,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2026-04-27',
  //   'endDate': '2026-07-26',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '8b3c15f8-7d3e-4572-a247-35d832c37a43',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 12 (Month 60)',
  //         'code': 'V12',
  //         'position': 11,
  //         'scheduledDay': 1800,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2026-10-24',
  //   'endDate': '2027-01-22',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'ce9558bc-8edd-47fc-8bae-9f7d6aeec963',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 12 (Month 66)',
  //         'code': 'R12',
  //         'position': 12,
  //         'scheduledDay': 1980,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2027-04-22',
  //   'endDate': '2027-07-21',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '6e409f7d-b3ce-4df2-8345-92458e8ac2e2',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 13 (Month 72)',
  //         'code': 'V13',
  //         'position': 13,
  //         'scheduledDay': 2160,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2027-10-19',
  //   'endDate': '2028-01-17',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'a5188b05-9902-473a-865c-05e948cbe7f3',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 13 (Month 78)',
  //         'code': 'R13',
  //         'position': 14,
  //         'scheduledDay': 2340,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2028-04-16',
  //   'endDate': '2028-07-15',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'ee87ed2f-5511-4481-8e4c-8513c6df2258',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 14 (Month 84)',
  //         'code': 'V14',
  //         'position': 15,
  //         'scheduledDay': 2520,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2028-10-13',
  //   'endDate': '2029-01-11',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '59ab5b2f-d84d-4fde-8ff2-226aa0fd5d4d',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 14 (Month 90)',
  //         'code': 'R14',
  //         'position': 16,
  //         'scheduledDay': 2700,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2029-04-11',
  //   'endDate': '2029-07-10',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'c45711cc-4f7f-470e-90d5-9cbe03cfe8a2',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 15 (Month 96)',
  //         'code': 'V15',
  //         'position': 17,
  //         'scheduledDay': 2880,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2029-10-08',
  //   'endDate': '2030-01-06',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'ee96cb92-b91c-4c23-b4db-a9104b774513',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 15 (Month 102)',
  //         'code': 'R15',
  //         'position': 18,
  //         'scheduledDay': 3060,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2030-04-06',
  //   'endDate': '2030-07-05',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '2fa8ac87-a1c8-4f10-83b9-0c9ca9e9654f',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 16 (Month 108)',
  //         'code': 'V16',
  //         'position': 19,
  //         'scheduledDay': 3240,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2030-10-03',
  //   'endDate': '2031-01-01',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'ea266fd3-6f0e-40a3-9fbe-35c0144ea467',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 16 (Month 114)',
  //         'code': 'R16',
  //         'position': 20,
  //         'scheduledDay': 3420,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2031-04-01',
  //   'endDate': '2031-06-30',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '6fcaadf6-d488-48fa-af65-4f9f301a80a0',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 17 (Month 120)',
  //         'code': 'V17',
  //         'position': 21,
  //         'scheduledDay': 3600,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2031-09-28',
  //   'endDate': '2031-12-27',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '1191d3e8-122b-49ef-8982-0575580a384f',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 17 (Month 126)',
  //         'code': 'R17',
  //         'position': 22,
  //         'scheduledDay': 3780,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2032-03-26',
  //   'endDate': '2032-06-24',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '4a41d184-b576-476d-80cb-3053fbca373f',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 18 (Month 132)',
  //         'code': 'V18',
  //         'position': 23,
  //         'scheduledDay': 3960,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2032-09-22',
  //   'endDate': '2032-12-21',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '0886d04b-4b7e-45d4-a885-f2c3f850a4df',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 18 (Month 138)',
  //         'code': 'R18',
  //         'position': 24,
  //         'scheduledDay': 4140,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2033-03-21',
  //   'endDate': '2033-06-19',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'cf2bbb30-3937-430c-a0e2-ed1a0d381fa8',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 19 (Month 144)',
  //         'code': 'V19',
  //         'position': 25,
  //         'scheduledDay': 4320,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2033-09-17',
  //   'endDate': '2033-12-16',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': '847cf39c-cfd7-4009-8405-db3462569a50',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Remote Visit 19 (Month 150)',
  //         'code': 'R19',
  //         'position': 26,
  //         'scheduledDay': 4500,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // },
  // {
  //   'startDate': '2034-03-16',
  //   'endDate': '2034-06-14',
  //   'visits': [
  //     {
  //       'visitTemplate': {
  //         'id': 'a6c454e1-9769-4a84-91f6-21974d0a8a63',
  //         'protocolVersion': {
  //           'id': 'f23ca5c3-b7d9-464c-8dad-d7acdd6df76a',
  //           'amendmentNumber': 3,
  //           'amendmentDate': '2021-02-24',
  //           'versionName': '2.0',
  //           'consentRequired': false,
  //           'changeLog': null,
  //           '__typename': 'ProtocolVersion'
  //         },
  //         'name': 'Visit 20 (Month 156)',
  //         'code': 'V20',
  //         'position': 27,
  //         'scheduledDay': 4680,
  //         'windowDelta': 45,
  //         'isSkippable': true,
  //         'visitType': 'standard',
  //         '__typename': 'VisitTemplate'
  //       },
  //       'visitInstance': null,
  //       '__typename': 'ParticipantVisit'
  //     }
  //   ],
  //   '__typename': 'ParticipantVisitWindow'
  // }
]
