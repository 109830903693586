<script>
// TODO: this view can be removed once all of our sites have the visit_windows flag enabled
import ParticipantHeader from '@/components/PageHeader/ParticipantHeader'
import Tooltip from '@/components/Tooltip/Tooltip'
import ParticipantVisits from '@/components/ParticipantVisits/ParticipantVisits'
import SiteTransferLog from '@/components/SiteTransferLog/SiteTransferLog'
import SiteTransferModal from '@/components/SiteTransferModal/SiteTransferModal'
import detectModule from '@/mixins/detectModule'
import participant from '@/mixins/queries/participant'
import sites from '@/mixins/queries/sites'
import cohorts from '@/mixins/queries/cohorts'
import siteTransfers from '@/mixins/queries/siteTransfers'
import participantVisits from '@/mixins/queries/participantVisits'

export default {
  components: {
    ParticipantHeader,
    Tooltip,
    ParticipantVisits,
    SiteTransferLog,
    SiteTransferModal
  },
  mixins: [
    detectModule,
    participant,
    sites,
    cohorts,
    siteTransfers,
    participantVisits
  ]
}
</script>
<template>
  <div class="visit-schedule">
    <ParticipantHeader
      :participant="participant"
      :loading="$apollo.queries.participant.loading"
      :is-e-d-c-active="isEDCActive"
    >
      <template v-if="isCTMSActive">
        <dl class="participant-details">
          <div class="participant-details__field">
            <dt>Status</dt>
            <dd>{{ participantStatus | capitalize }}</dd>
          </div>
          <div class="participant-details__field">
            <dt>Cohort</dt>
            <dd>{{ getCohortName(participant.cohortId) }}</dd>
          </div>
          <div
            v-if="participantSubgroups"
            class="participant-details__field"
          >
            <dt>Subgroups</dt>
            <dd>{{ participantSubgroups }}</dd>
          </div>
          <div class="participant-details__field">
            <dt>Site</dt>
            <dd>
              {{ getSiteName(participant.siteId) }}
              <Tooltip
                v-if="canManageSiteTransfers"
                class="participant-details__manage-site"
                :condition="hasInProgressVisit"
                content="Cannot change site with in-progress visit"
                placement="right"
              >
                <a @click="openSiteTransferModal">
                  Manage Site
                </a>
              </Tooltip>
            </dd>
          </div>
        </dl>
      </template>
    </ParticipantHeader>

    <p class="print-only print-disclaimer">
      Note to site: please redact the Participant ID before sharing this schedule with the participant.
    </p>

    <ParticipantVisits
      :participant="participant"
      :participant-visits="participantVisits"
      :is-participant-excluded="isParticipantExcluded"
      :schedule-base-date="participantScheduleBaseDate"
      :sites="sites"
      :loading="$apollo.queries.participantVisits.loading"
    />

    <SiteTransferLog
      v-if="siteTransfers.length"
      :site-transfers="siteTransfers"
    />

    <SiteTransferModal
      v-if="displaySiteTransferModal"
      :participant="participant"
      :sites="sites"
      @close="displaySiteTransferModal = false"
    />
  </div>
</template>
