<template>
  <Modal
    class="site-transfer-modal"
    title="Manage Site"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <h3 class="site-transfer-modal__heading">
        Site Transfer
      </h3>
      <BfInput
        label="Current Site"
        :value="currentSite"
        :disabled="true"
      />
      <BfSelect
        v-model="destinationSite"
        label="Destination Site"
        placeholder="Select"
      >
        <el-option
          v-for="site in sites.filter(site => site.name !== currentSite)"
          :key="site.id"
          :label="site.name"
          :value="site.id"
        />
      </BfSelect>
      <BfAlert
        v-if="protocolError"
        type="error"
        icon="error"
      >
        {{ protocolError | capitalize }}.
      </BfAlert>
      <BfSelect
        v-model="transferReason"
        label="Primary Reason for Site Transfer"
        placeholder="Select"
      >
        <el-option
          v-for="(reason, index) in reasons"
          :key="index"
          :label="reason"
          :value="reason"
        />
      </BfSelect>
      <ValidationProvider
        v-slot="{ errors }"
        :rules="`${showReasonComment ? 'required' : ''}`"
      >
        <BfTextArea
          v-if="showReasonComment"
          v-model="transferReasonComment"
          :error="errors[0]"
          class="transfer-reason-textarea"
          label="Please specify"
        />
      </ValidationProvider>
      <div class="confirm-checkbox">
        <label
          for="confirmSiteTransfer"
          class="confirm-label"
        >
          Confirm
        </label>
        <p class="subtext">
          Transfering a participant to another site cannot be undone.
        </p>
        <BfCheckbox
          id="confirmSiteTransfer"
          v-model="confirmChecked"
          :label="`Confirm site transfer of participant ${participant.customerDefinedId}.`"
          :required="true"
        />
      </div>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        :disabled="!canSubmitForm"
        @click="transferSite"
      >
        Complete Site Transfer
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfInput from '@/components/BfInput/BfInput'
import BfSelect from '@/components/BfSelect/BfSelect'
import BfAlert from '@/components/BfAlert/BfAlert'
import BfTextArea from '@/components/BfTextArea/BfTextArea'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'
import UPDATE_PARTICIPANT_SITE_MUTATION from '@/graphql/participants/UpdateParticipantSiteMutation.graphql'
import GET_RESTRICTED_PARTICIPANT from '@/graphql/participants/GetRestrictedParticipant.graphql'
import GET_PARTICIPANT_SITE_TRANSFERS from '@/graphql/participants/GetParticipantSiteTransfersQuery.graphql'
import sites from '@/mixins/queries/sites'
import { logError } from '@/utils/logging'

export default {
  components: {
    Modal,
    BfInput,
    BfSelect,
    BfAlert,
    BfTextArea,
    BfCheckbox
  },
  mixins: [ sites ],
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reasons: [
        'Participant move/relocation',
        'Participant preference (not COVID-19 related)',
        'Travel burden (participant or study partner)',
        'Travel restriction due to COVID-19',
        'Prodromal screening transfer (from imaging site to clinical site)',
        'Site closure',
        'Other (reason below is required)'
      ],
      destinationSite: null,
      transferReason: null,
      transferReasonComment: '',
      confirmChecked: false,
      protocolError: null
    }
  },
  computed: {
    /**
     * Get the site associated with the participant.
     * @returns {string} - the name of the participant's site
     */
    currentSite() {
      return this.getSiteName(this.participant.siteId)
    },

    /**
     * Determine if the comment field for transfer reason must be displayed.
     * @returns {boolean}
     */
    showReasonComment() {
      return this.transferReason && this.transferReason.startsWith('Other')
    },

    /**
     * Detect if the data is valid and the form may be submitted.
     * @returns {boolean}
     */
    canSubmitForm() {
      return this.destinationSite && !this.protocolError &&
        this.confirmChecked && this.transferReason &&
        (!this.showReasonComment || (this.showReasonComment && this.transferReasonComment))
    }
  },
  watch: {
    // Clear the error state when the destinationSite is updated.
    destinationSite: function() {
      this.protocolError = null
    }
  },
  methods: {
    /**
     * Perform the mutation to transfer the participant to a new site
     */
    transferSite() {
      this.$apollo.mutate({
        mutation: UPDATE_PARTICIPANT_SITE_MUTATION,
        variables: {
          participantId: this.participant.id,
          siteId: this.destinationSite,
          reason: this.transferReasonComment || this.transferReason
        },
        update: (store, { data }) => {
          this.updateRestrictedParticipantInStore(store)
          this.writeParticipantSiteTransferToStore(store, data.updateParticipantSite)
        }
      }).then(response => {
        this.$emit('close')
      }).catch(error => {
        const userFriendlyError = error.message.substr(error.message.lastIndexOf(':') + 1).trim().replace('"', '')
        // Set any errors to be displayed in the UI
        this.protocolError = userFriendlyError
        logError(error, 'SiteTransferModal.vue update participant site mutation')
      })
    },

    /**
     * Update the Restricted Participant in the local store
     * @param store - the local store
     */
    updateRestrictedParticipantInStore(store) {
      // retrieve the RestrictedParticipant stored in the local store
      const data = store.readQuery({
        query: GET_RESTRICTED_PARTICIPANT,
        variables: {
          participantId: this.$route.params.participantId,
          studyId: this.$route.params.studyId
        }
      })
      // modify the RestrictedParticipant with the destination site's siteId
      data.getParticipantRestricted.siteId = this.destinationSite
      // write the updated RestrictedParticipant to the store
      store.writeQuery({
        query: GET_RESTRICTED_PARTICIPANT,
        variables: {
          participantId: this.$route.params.participantId,
          studyId: this.$route.params.studyId
        },
        data
      })
    },

    /**
     * Write the new transfer to the local store to prevent an inaccurate UI.
     * @param store - the local store
     * @param data - the data returned by a successful mutation
     */
    writeParticipantSiteTransferToStore(store, transferToAdd) {
      const data = store.readQuery({
        query: GET_PARTICIPANT_SITE_TRANSFERS,
        variables: {
          participantId: this.$route.params.participantId,
          studyId: this.$route.params.studyId
        }
      })

      // Due to an Apollo bug, the mutations update() function may be run twice.
      // We need to ensure that we only add the item once. Since transfers do not have an unique id,
      // we have to check a handful of object properties to determine if there is a match.
      if (!data.getParticipantSiteTransfers.some(transfer => (
        transfer.fromSiteId === transferToAdd.fromSiteId &&
        transfer.reason === transferToAdd.reason &&
        transfer.transferredAt === transferToAdd.transferredAt
      ))) {
        data.getParticipantSiteTransfers.unshift(transferToAdd)
        store.writeQuery({
          query: GET_PARTICIPANT_SITE_TRANSFERS,
          variables: {
            participantId: this.$route.params.participantId,
            studyId: this.$route.params.studyId
          },
          data
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .site-transfer-modal {
    &__heading {
      margin: 0;
      margin-bottom: 1.5rem;
      @include text-style('title', 'medium', 'regular');
    }

    .modal__content > * {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: .5rem;
      }
    }

    .modal__content > span {
      display: block; /* needed to resolve layout issues from span rendered by ValidationProvider */
    }

    .bf-input:not(.bf-text-area),
    .bf-select {
      .field-wrap {
        max-width: 20rem;
      }
    }

    .bf-alert {
      margin-top: -.75rem;
      margin-bottom: 1.5rem;
    }

    .confirm-label {
      @include text-style('paragraph', 'small', 'regular');
    }

    .subtext {
      margin-top: .25rem;
      margin-bottom: .5rem;
      @include text-style('interface', 'small', 'regular');
      color: $hillcock;
    }

    .bf-checkbox {
      &.form-field .field-wrap label {
        margin-bottom: 0;
        @include text-style('paragraph', 'small', 'medium');
      }
    }
  }
</style>
