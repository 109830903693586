<script>
/**
 * A convenience component for displaying visits within a visit window in list.
 */
import sites from '@/mixins/queries/sites'
import { isVisitIntanceComplete, getVisitLabel } from '@/utils/visit'
import visitInstanceStates from '@/mixins/visitInstanceStates'
import { VisitStatus } from '@/utils/constants'
import { displayAbbreviatedDate } from '@/utils/date'

export default {
  components: {
  },
  mixins: [ sites, visitInstanceStates ],
  props: {
    visits: {
      type: Array,
      required: true
    }
  },
  methods: {
    visitLabel(visit) {
      return `${getVisitLabel(visit)}`
    },

    visitStatus(visit) {
      if (!visit.visitInstance) {
        return 'Not Started'
      }

      switch (visit.visitInstance.status) {
        case VisitStatus.COMPLETE:
          return `Completed on ${displayAbbreviatedDate(visit.visitInstance.endDate)}`
        case VisitStatus.NOT_COMPLETING:
          return `Marked as Did Not Complete on ${displayAbbreviatedDate(visit.visitInstance.notCompletingDate)}`
        case VisitStatus.REOPENED:
          return `Reopened on ${displayAbbreviatedDate(visit.visitInstance.reopenDate)}`
        case VisitStatus.IN_PROGRESS:
          return `Started on ${displayAbbreviatedDate(visit.visitInstance.startDate)}`
        default:
          return 'INVALID STATUS'
      }
    },

    isVisitComplete(visit) {
      return visit.visitInstance && isVisitIntanceComplete(visit.visitInstance)
    }
  }
}
</script>

<template>
  <ul class="window-visits">
    <li
      v-for="visit in visits"
      :key="visit.id"
      class="window-visit"
    >
      <div class="visit-wrapper">
        <span class="visit-name">
          {{ visitLabel(visit) }}
        </span>
        <span class="visit-status">
          {{ visitStatus(visit) }}
        </span>
      </div>
    </li>
  </ul>
</template>

<style lang="scss">
  .window-visits {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .visit-wrapper {
        display: flex;
      }
    }

    span {
      color: $black;
      @include text-style('paragraph', 'small', 'medium');
    }

    .visit-name {
      min-width: 16rem;
      max-width: 100%;
    }

    .visit-status {
      margin-left: .5rem;
      @include text-weight('regular');
    }

    @media print {
      li + li {
        margin-top: .25rem;
      }

      span {
        @include text-style('interface', 'small', 'regular');
      }

      .visit-status {
        display: none;
      }
    }
  }
</style>
