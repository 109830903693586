<template>
  <div
    class="visit-box"
    :class="{ 'out-of-window': !isBeforeEndOfWindow }"
  >
    <div class="visit-box__info">
      <StatusIcon
        class="visit-box__icon"
        :status="!isWithinVisitWindow ? 'clock' : visit.status"
      />
      <div class="visit-box__title">
        <h5>{{ visit.name }}</h5>
        <span class="visit-box__meta">
          {{ visitStartDate ? 'Started' : visitDateDisplay ? 'Estimated' : '' }}
          {{ visitDateDisplay ? `${visitDateDisplay} • ` : '' }}
          {{ visitStartDate ? `${currentProgress}% Complete` : 'Not Started' }}
        </span>
      </div>
    </div>
    <div class="visit-box__actions">
      <el-button
        type="primary"
        :disabled="!isOnline"
        @click="handleOpenVisit"
      >
        {{ inProgress ? 'Resume' : 'Start' }} Visit
      </el-button>
      <ContextMenu
        v-if="hasContextOptions"
        class="visit-menu"
        :tabindex="-1"
      >
        <el-dropdown-item
          v-if="displayDidNotCompleteOption"
          @click.native="handleSkipVisit"
        >
          Did Not Complete
        </el-dropdown-item>
      </ContextMenu>
    </div>
  </div>
</template>

<script>
import StatusIcon from '@/components/StatusIcon/StatusIcon'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import { getVisitInstanceStartDate, getVisitCompletionPercent } from '@/utils/visit'
import { VisitStatus, FormStatus } from '@/utils/constants'
import { datePlusDays, displayFullDate } from '@/utils/date'
import { logError } from '@/utils/logging'
import { mapState } from 'vuex'

export default {
  components: {
    StatusIcon,
    ContextMenu
  },
  props: {
    visit: {
      type: Object,
      required: true
    },
    scheduleBaseDate: {
      type: String,
      default: ''
    }
  },
  apollo: {
    visitData () {
      return {
        query: GET_VISIT_QUERY,
        variables() {
          return { visitInstanceId: this.visit.instanceID }
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'VisitBox.vue get visit query')
        },
        skip() {
          return !this.visit.instanceID
        }
      }
    }
  },
  data() {
    return {
      visitData: {
        formInstances: []
      }
    }
  },
  computed: {
    ...mapState(['isOnline']),

    inProgress() {
      return this.visit.status === VisitStatus.IN_PROGRESS
    },

    /**
     * Determine if any data has been added to the visit.
     * @returns {boolean}
     */
    visitHasData() {
      return this.visitData.formInstances.some(formInstance => formInstance.status !== FormStatus.NOT_STARTED)
    },

    /**
     * Check if the visit is skippable.
     * If a visit has been started but no data is associated with it, it can be marked as "Did Not Complete".
     * @returns {boolean}
     */
    displayDidNotCompleteOption() {
      return this.visit.isSkippable && !this.visitHasData
    },

    hasContextOptions() {
      return this.displayDidNotCompleteOption
    },

    visitStartDate() {
      return getVisitInstanceStartDate(this.visit)
    },

    /**
     * Get the display date for the current visit.
     * This will display the start date if the visit is not completed.
     * Will display the completed date after a visit has been completed.
     * Will display the estimated date if no baseline date has been established.
     * @returns {string} - a date string
     */
    visitDateDisplay() {
      if (!this.visitStartDate) {
        return this.estimatedVisitDate ? displayFullDate(this.estimatedVisitDate) : ''
      }
      return this.visitStartDate
    },

    /*
     * The window will be based off the calculation date.
     * Use the calculation date to determine the estimated visit date.
     */
    estimatedVisitDate() {
      if (this.scheduleBaseDate) {
        return datePlusDays(this.scheduleBaseDate, this.visit.scheduledDay)
      }
      return null
    },

    /*
     * Utilities to compare today's date to the visit window.
     */
    isWithinVisitWindow() {
      return this.checkVisitWindow()
    },

    isBeforeEndOfWindow() {
      // passes true to signify that the beginning of the window is ignored
      return this.checkVisitWindow(true)
    },

    /*
     * Checks the form statuses within the visit to calculate completion percentage.
     */
    currentProgress() {
      return this.visitData.formInstances.length ? getVisitCompletionPercent(this.visitData.formInstances) : 0
    }
  },
  methods: {
    // box clicks are pushed up to the ParticipantDetail view to avoid redundancies
    // skipped visits can not be opened.
    handleOpenVisit() {
      if (!this.isSkipped) {
        this.$emit('box-click', this.visit)
      }
    },

    handleSkipVisit() {
      this.$emit('skip-visit', this.visit)
    },

    /*
     * Check the estimated visit date and use the windowDelta determine if today's date is within the window.
     * The windowDelta is the number of days before and after the estimated visit date.
     * If today's date fall's within that range, a visit is classified as "In Window"
     *
     * An agument may be passed to ignore the beginning of the window.
     */
    checkVisitWindow(ignoreStart = false) {
      // If the visit is in_progress, a screening visit or has no estimated date - it is within window.
      if (
        this.visit.status === VisitStatus.IN_PROGRESS ||
        this.visit.scheduledDay < 0 ||
        this.estimatedVisitDate === null
      ) {
        return true
      }

      // calculate the beginning of the window
      let estimatedDate = new Date(this.estimatedVisitDate)
      let startOfWindow = estimatedDate.setDate(estimatedDate.getDate() - this.visit.windowDelta)
      if (ignoreStart) {
        startOfWindow = new Date() // if ignoring the start of the window, the start is set as today.
      }

      // calculate the end of the window
      estimatedDate = new Date(this.estimatedVisitDate) // reset estimated date for calculation
      const endOfWindow = estimatedDate.setDate(estimatedDate.getDate() + this.visit.windowDelta)

      // is today within the window?
      const today = new Date()
      if (today >= startOfWindow && today <= endOfWindow) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .visit-box {
    width: 100%;
    padding: 2rem 1.5rem;
    border: 1px solid $axon;
    background-color: $white-matter;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    // TODO use Status component instead
    &__icon {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      fill: $dopamine;
      flex-shrink: 0;

      &.complete {
        fill: $success;
      }
    }

    &__title {
      display: grid;
      grid-template: auto auto / 1fr;

      h5 {
        margin: 0;
        margin-bottom: .25rem;
        @include text-style('title', 'medium', 'medium');
      }
    }

    &__meta {
      color: $hillcock;
      @include text-style('interface', 'medium', 'regular')
    }

    &__actions {
      margin-left: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .visit-menu {
      margin-left: .5rem;
    }

    &.out-of-window {
      .visit-box__icon {
        fill: $error;
      }
    }
  }
</style>
