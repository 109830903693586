<template>
  <div class="participant-visits">
    <section
      v-if="!isParticipantExcluded && isEDCActive"
      class="next-visit"
    >
      <h3 class="participant-visits__heading">
        {{ activeVisit.inProgress ? 'Current Visit' : 'Next Visit' }}
      </h3>
      <div v-if="isEDCActive && activeVisit">
        <VisitBox
          v-loading="loading"
          :visit="activeVisit"
          :schedule-base-date="scheduleBaseDate"
          :is-active="activeVisit.inProgress"
          @box-click="openVisit"
          @skip-visit="skipVisit(activeVisit)"
        />
      </div>
    </section>
    <ParticipantStudies
      v-if="isCTMSActive"
      :participant="participant"
      class="participant-studies"
    />
    <section class="full-schedule">
      <h3 class="participant-visits__heading">
        Full Schedule
      </h3>
      <div
        v-if="visitsToDisplay.length"
        class="participant-visits-table-container"
      >
        <ParticipantVisitsTable
          v-loading="loading"
          :visits="visitsToDisplay"
          :sites="sites"
          :active-visit="activeVisit"
          :schedule-base-date="scheduleBaseDate"
          @row-click="openVisit"
          @skip-visit="skipVisit"
        />
      </div>
      <p v-else>
        No visits available.
      </p>
    </section>

    <StartVisitModal
      :visit="visitToStart"
      :display-modal="displayStartVisitModal"
      route-to-visit
      @close="displayStartVisitModal = false"
    />

    <SkipVisitModal
      v-if="displaySkipVisitModal"
      :visit="visitToSkip"
      :visit-instance-id="visitToSkip.instanceID ? visitToSkip.instanceID : null"
      @close="displaySkipVisitModal = false"
    />
  </div>
</template>

<script>
// TODO: delete component(s) after VW implementation in place
import VisitBox from './VisitBox'
import ParticipantVisitsTable from '@/components/ParticipantVisits/ParticipantVisitsTable'
import ParticipantStudies from '@/components/ParticipantStudies/ParticipantStudies'
import StartVisitModal from '@/components/StartVisitModal/StartVisitModal'
import SkipVisitModal from '@/components/SkipVisitModal/SkipVisitModal'
import detectModule from '@/mixins/detectModule'
import { formatVisits, getActiveVisit } from '@/utils/visit'
import { VisitStatus } from '@/utils/constants'
import { descend, prop } from 'ramda'
import { mapState } from 'vuex'

export default {
  components: {
    VisitBox,
    ParticipantVisitsTable,
    StartVisitModal,
    SkipVisitModal,
    ParticipantStudies
  },
  mixins: [ detectModule ],
  props: {
    participant: {
      type: Object,
      default: () => {}
    },
    participantVisits: {
      type: Array,
      required: true
    },
    isParticipantExcluded: {
      type: Boolean,
      default: false
    },
    scheduleBaseDate: {
      type: String,
      default: ''
    },
    sites: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayStartVisitModal: false,
      displaySkipVisitModal: false,
      visitToStart: {}, // this will be the visit passed to NewVisitModal
      visitToSkip: {}
    }
  },
  computed: {
    ...mapState(['isOnline']),

    /*
     * Flatten and sort the given participant visit objects
     */
    visits() {
      return this.participantVisits?.length ? formatVisits(this.participantVisits) : []
    },

    /*
     * If a participant is excluded, we only want to show complete or skipped visits.
     */
    visitsToDisplay() {
      const filteredVisits = this.visits.filter(visit => visit.status !== VisitStatus.COMPLETED_EXTERNALLY)
      const baseVisits =
        filteredVisits.filter(visit =>
          [VisitStatus.COMPLETE, VisitStatus.NOT_COMPLETING, VisitStatus.REOPENED].includes(visit.status)
        )

      // If the participant status is `excluded`, then ONLY show completed or skipped visits
      return this.isParticipantExcluded
        ? baseVisits.sort(descend(prop('position')))
        : filteredVisits
    },

    /**
     * Get the next or current visit
     * @returns {Object}
     */
    activeVisit() {
      return getActiveVisit(this.visits)
    }
  },
  methods: {
    openVisit(visit) {
      this.startOrResumeVisit(visit)
    },

    skipVisit(visit) {
      this.visitToSkip = visit
      this.displaySkipVisitModal = true
    },

    startOrResumeVisit(visit) {
      if (!this.isOnline) { return } // cancel action if offline
      if (visit.instanceID) {
        // load the visitInstance if visit is in_progress or complete
        this.resumeVisit(visit)
      } else if (this.isEDCActive) {
        // display the Start Visit Modal
        this.visitToStart = visit
        this.displayStartVisitModal = true
      }
    },

    resumeVisit(visit) {
      this.$router.push({
        name: !this.isEDCActive ? 'visitDetail' : 'visitManagement',
        params: {
          ...this.$route.params,
          visitInstanceId: visit.instanceID
        }
      })
    }
  }
}
</script>

<style lang="scss" >
.participant-visits {
  margin-bottom: 1.5rem;

  &__heading {
    margin: 0;
    margin-bottom: 1rem;
    @include text-style('title', 'medium', 'medium');
  }

  .next-visit {
    padding-bottom: 1.5rem;
  }

  .participant-studies {
    margin-bottom: 2rem;
  }
}
</style>
